import { routes } from "@/routes";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import useCustomQuery from "@hooks/queries/useCustomQuery";
import * as APIS from "@services/apis";
import { useDispatch } from "react-redux";

export interface ITradeIngResponse {
  requestAt: string;
  tradeType: string;
  requestId: number;
  status: string;
  statusDate: string | null;
  statusDateType: string | null;
  tradeNumber: string;
  assetType: string;
  productName: string;
  totalCount: number;
  requestKrw: number;
  requestPrice: number;
  requestGram: number;
  contractGram: number;
  userFee: number;
  imagePath: string;
  shopName: string;
  associateName: string | null;
  addTradeType: string | null; // 택배 여부
  brandName?: string;
  option?: string;
  assetTypeKor: string;
  tradeTypeKor: string;
  pureTradeKrw: number;
  tradeGram: number;
  tradeKrw: number;
  tradePoint: number;
  responseId: number;
  rawId: number;
}

const useTradeIngList = () => {
  const dispatch = useDispatch();

  const fetchDetails = async () => {
    dispatch(showLoading());
    try {
      const response = await APIS.getTradeHistoryIngList();
      dispatch(hideLoading());
      if (response.status === 200) {
        if (response.data.success) {
          return response.data.data.length
            ? (response.data.data as ITradeIngResponse[])
            : "이용 내역이 없습니다.";
        } else {
          return response.data.message || "";
        }
      } else {
        throw new Error(`이용 내역 조회에 실패했습니다.`);
      }
    } catch (error: any) {
      dispatch(hideLoading());
      throw new Error(error.message || "알 수 없는 오류 발생");
    }
  };

  const { data, error, isLoading, refetch, isFetched } = useCustomQuery<
    ITradeIngResponse[] | string
  >([routes.tradeHistory, "ing"], fetchDetails, {
    staleTime: 0,
    gcTime: 0,
  });

  const getMessage = () => {
    if (isLoading) return "이용 내역 확인 예정입니다.";
    if (error) return error.message || "오류가 발생했습니다.";
    if (typeof data === "string") return data;
    return undefined;
  };

  return {
    TradeIngList: Array.isArray(data) ? data : undefined,
    isLoading,
    error,
    isFetched,
    IngRefetch: refetch,
    noRecordsMessage: getMessage(),
  };
};

export default useTradeIngList;
