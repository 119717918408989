import { ROLE, routes } from "@/routes";
import { store } from "@app/store";
import { updateAuth } from "@features/auth/authSlice";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { closeModal } from "@features/modal/modalSlice";
import { showToast } from "@features/toast/toastSlice";
import { userRefresh } from "@features/user/userSlice";
import useCustomMutation from "@hooks/queries/useCustomMutation";
import usePendingCheck from "@hooks/usePendingCheck";
import * as APIS from "@services/apis";
import { STORAGE_KEY } from "@utils/constants";
import { decodeJwt, postMessage } from "@utils/utils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IAccountInfo } from "./useAccountInfo";

interface AccessToken {
  name: string;
  roles: string;
  sns: string;
  sub: string;
  version: string;
}

interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

const useAccountChange = (
  accountType: "DEPOSIT" | "WITHDRAW",
  refetch: () => void,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pendingCheck = usePendingCheck();

  const accountChange =
    accountType === "DEPOSIT"
      ? APIS.postDepositFromWithdraw
      : APIS.postWithdrawFromDeposit;

  const pendingCompleteAccountCheck = (
    bankType: string,
    refetch: () => void,
  ) => {
    pendingCheck<IAccountInfo>({
      checkFn: () => APIS.getAccountInfo(bankType),
      successCondition: (data) => data.bankAccountStatus === "COMPLETED",
      processingCondition: (data) => data.bankAccountStatus === "REQUEST",
      successMessage: "계좌가 등록되었습니다.",
      processingMessage: "계좌 등록 중 입니다.",
      failureMessage: "계좌 등록에 실패하였습니다.",
      navigateOnSuccess: () => {
        dispatch(closeModal());
        refetch();
      },
      navigateOnProcessing: () => {
        dispatch(closeModal());
        navigate(routes.menu);
      },
      navigateOnFailure: () => {
        dispatch(closeModal());
      },
    });
  };

  const updateToken = (code: any) => {
    APIS.getAuthToken(code).then(
      ({ data: { success, data, message: errorMessage } }) => {
        if (success) {
          let accessToken = data.authToken;
          if (accessToken) {
            sessionStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
            APIS.setAuthorization(accessToken);
            const {
              name,
              roles = "NONE",
              sns,
              sub = "",
              version = "",
            } = decodeJwt(accessToken) as AccessToken;
            const ids = sub.split(":");
            let role = ROLE["NONE"];
            const splitRoles = roles.split(",");

            for (let name of splitRoles) {
              if (role?.LEVEL < ROLE[name]?.LEVEL) {
                role = ROLE[name];
              }
            }
            dispatch(
              updateAuth({
                isSigned: true,
                name,
                role,
                sns,
                socialId: +ids[0] || 0,
                userId: +ids[1] || 0,
                version,
                roles: splitRoles,
              }),
            );
            store.dispatch(userRefresh);
            if (accessToken) {
              const param = {
                command: "SEND_TOKEN",
                data: {
                  authToken: accessToken,
                  apiBaseUrl: import.meta.env.VITE_API_URL,
                },
              };
              postMessage(param);
            }
            if (accessToken) {
              APIS.putNotificationClear();
            }
          }
        } else {
          dispatch(showToast({ message: errorMessage, icon: "error" }));
        }
      },
    );
  };

  const mutationFn = async (bankType: string): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await accountChange();
      dispatch(hideLoading());
      if (response.data.success) {
        pendingCompleteAccountCheck(bankType, refetch);
        updateToken(response.data.data.code);
      } else {
        dispatch(
          showToast({ message: response.data.message, icon: "caution" }),
        );
        dispatch(closeModal());
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation([routes.myAccountManagement], mutationFn);

  return mutation;
};

export default useAccountChange;
