import { store } from "@app/store";
import { showToast } from "@features/toast/toastSlice";
import { PHONE_CODE } from "@utils/constants";
import { jwtDecode } from "jwt-decode";
import { escapeRegExp } from "lodash";
import moment from "moment-timezone";

import { routes } from "../routes";
import { signOut } from "./sign";

//JwtPayload 인터페이스 정의
interface JwtPayload {
  roles?: string;
  sub?: string;
}

/** 2번쨰 인자의 형태 문자열로 전환. 기본 YYYY-MM-DD */
export const formatTime = (date = new Date(), format = "YYYY-MM-DD") => {
  if (date) {
    return moment(date).locale("ko").format(format);
  } else {
    return "";
  }
};

export const numberWithCommas = (x: string | number | undefined) => {
  if (x) {
    let onlyNumber = x.toString().replace(/,/gi, "");
    let parts = onlyNumber.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  } else if (x === "") {
    return "";
  } else {
    return "0";
  }
};

export const getAppVersion = () => {
  const userAgent = navigator.userAgent;

  const reg = /v(\d*)\.(\d*)\.(\d*)/;
  const version = userAgent.match(reg);

  const codePushReg = /cv(\d*)/;
  const codePushVersion = userAgent.match(codePushReg);

  return (version?.[0] || "0") + "." + (codePushVersion?.[1] || "0");
};

export const versionParser = (text = "") => {
  const reg = /[^(0-9|.)]/g;
  const version = text.replace(reg, "");
  const versionArr = version.split(".");

  const parseVersion = versionArr.reduce((acc: number, cur: string, index) => {
    return acc + parseInt(cur) * Math.pow(100, versionArr.length - index);
  }, 0);

  return parseVersion;
};

export type PostMessageCommand =
  | "CUSTOM_LOG"
  | "SET_HEADER"
  | "WINDOW_OPEN"
  | "CHANGE_PASSWORD"
  | "CALL_FLAG_API"
  | "CAN_NOT_GO_BACK"
  | "WINDOW_OPEN" // 외부 브라우저(or 앱) 오픈
  | "TAB_OPEN" // 내부 탭 오픈
  | "LOG_APPS_FLYER"
  | "SEND_TOKEN"
  | "REQUEST_LOGIN";

interface PostOnlyCommand {
  command: PostMessageCommand;
  param?: never;
  data?: never;
}
interface PostWithParam {
  command: PostMessageCommand;
  param: Record<string, any>;
  data?: never;
}
interface PostWithData {
  command: PostMessageCommand;
  data: Record<string, any>;
  param?: never;
}
type PostMessageParams = PostOnlyCommand | PostWithData | PostWithParam;

export const postMessage = (param: PostMessageParams) => {
  try {
    if (window.GoldMarketApp) {
      window.GoldMarketApp.postMessage(JSON.stringify(param));
    } else {
      // console.log("can not post message");
    }
  } catch (err) {
    console.log("post Message Error", err);
  }
};

export const requestNativeLogin = (navigate: any) => {
  const appVersion = getAppVersion();
  if (versionParser(appVersion) >= versionParser("5.0.0.0")) {
    postMessage({
      command: "REQUEST_LOGIN",
      param: { uri: import.meta.env.VITE_API_URL },
    });
  } else {
    navigate(routes.signIn);
  }
};

export const decodeJwt = (token: any): JwtPayload | null => {
  if (token) {
    return jwtDecode(token);
  } else {
    return null;
  }
};

export function objToQueryString(obj: Record<string, any>): string {
  var keyValuePairs = [];
  let result = "";

  for (var key in obj) {
    if (obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    } else {
      if (obj.hasOwnProperty(key)) {
        keyValuePairs.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]),
        );
      }
      result = "?" + keyValuePairs.join("&");
    }
  }
  return result;
}

export const expireAuth = () => {
  store.dispatch(
    showToast({
      message: "인증 정보가 만료되었습니다. 다시 로그인해주세요.",
      icon: "error",
    }),
  );
  return signOut();
};

/** Date 유틸함수. 첫 인자에 따라서 년월일 계산 */
export const calcDate = (unit: string, value: number, base = new Date()) => {
  const copiedDate = new Date(base);
  switch (unit) {
    case "Y":
      copiedDate.setFullYear(copiedDate.getFullYear() + value);
      return copiedDate;
    case "M":
      const currentDate = copiedDate.getDate(); // 현재 날짜를 저장
      const targetMonth = copiedDate.getMonth() + value;
      copiedDate.setMonth(targetMonth);

      // 월을 더한 후 해당 월에 현재 날짜가 없을 경우, 마지막 날로 설정
      if (copiedDate.getDate() !== currentDate) {
        copiedDate.setDate(0); // 해당 월의 마지막 날로 설정
      }
      return copiedDate;
    case "MI":
      copiedDate.setMinutes(copiedDate.getMinutes() + value);
      return copiedDate;
    default:
      copiedDate.setDate(copiedDate.getDate() + value);
      return copiedDate;
  }
};

export const getCurrentMyPosition = () => {
  const geolocationOptions = {
    timeout: 1000 * 10, // 캐시 사용
    maximumAge: 1000 * 3600 * 24, // 캐시값 사용 가능한 시간
  };
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords } = position;
          resolve({ lat: coords.latitude, lng: coords.longitude });
        },
        () => {
          reject({ lat: 0, lng: 0 });
        },
        geolocationOptions,
      );
    } else {
      // Fallback for no geolocation
      reject({ lat: 0, lng: 0 });
    }
  });
};

export const getDistanceFromLatLonInKm = (
  lat1: any,
  lng1: any,
  lat2: any,
  lng2: any,
) => {
  function deg2rad(deg: any) {
    return deg * (Math.PI / 180);
  }
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lng2 - lng1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

export const numFormat = (num: number) => {
  if (num) {
    return Number(num).toLocaleString();
  } else {
    return "";
  }
};

export const ch2pattern = (ch: any) => {
  const offset = 44032; /* '가'의 코드 */
  // 한국어 음절
  if (/[가-힣]/.test(ch)) {
    const chCode = ch.charCodeAt(0) - offset;
    // 종성이 있으면 문자 그대로를 찾는다.
    if (chCode % 28 > 0) {
      return ch;
    }
    const begin = Math.floor(chCode / 28) * 28 + offset;
    const end = begin + 27;
    return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
  }
  // 한글 자음
  if (/[ㄱ-ㅎ]/.test(ch)) {
    const con2syl: { [key: string]: number } = {
      ㄱ: "가".charCodeAt(0),
      ㄲ: "까".charCodeAt(0),
      ㄴ: "나".charCodeAt(0),
      ㄷ: "다".charCodeAt(0),
      ㄸ: "따".charCodeAt(0),
      ㄹ: "라".charCodeAt(0),
      ㅁ: "마".charCodeAt(0),
      ㅂ: "바".charCodeAt(0),
      ㅃ: "빠".charCodeAt(0),
      ㅅ: "사".charCodeAt(0),
    };
    const begin =
      con2syl[ch] ||
      (ch.charCodeAt(0) - 12613) /* 'ㅅ'의 코드 */ * 588 + con2syl["ㅅ"];
    const end = begin + 587;
    return `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
  }
  // 그 외엔 그대로 내보냄
  // escapeRegExp는 lodash에서 가져옴
  return escapeRegExp(ch);
};

export const phoneNumberFormat = (phone?: string) => {
  return phone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

const daysOfWeek = [
  "일요일",
  "월요일",
  "화요일",
  "수요일",
  "목요일",
  "금요일",
  "토요일",
];

// 요일 변환
export const getKoreanDayOfWeek = (dateString: string): string => {
  const [year, month, day] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, day);
  const dayIndex = date.getDay(); // 0(일요일)부터 6(토요일)

  return daysOfWeek[dayIndex];
};

export const formatDateString = (dateString: string | null): string => {
  const date = moment(dateString, "YYYY-MM-DD");
  const year = date.format("YY");
  const month = date.format("MM");
  const day = date.format("DD");
  const dayOfWeek = daysOfWeek[date.day()];

  return `${year}년 ${month}월 ${day}일 ${dayOfWeek}`;
};

// yyyy-mm-ddThh:mm:ss => yyyy.MM.dd hh:mm
export const formatDateTime = (dateTimeString: string): string => {
  const date = new Date(dateTimeString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}.${month}.${day} ${hours}:${minutes}`;
};

export const formatDate = (
  dateString: string | null,
  format: string = "YYYY.MM.DD",
): string => {
  if (!dateString) return "";
  return moment(dateString).format(format);
};

export const validateEmail = (email: string) => {
  const regex =
    /^[-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]+@[-0-9A-Za-z!#$%&'*+/=?^_`{|}~]+[.]{1}[0-9A-Za-z]/;

  return regex.test(email);
};
interface PhoneNumber {
  prefix: string;
  part1: string;
  part2: string;
}

export const validatePhone = (phone: PhoneNumber) => {
  const phoneCodePattern = PHONE_CODE.join("|");
  const regex = new RegExp(`^(${phoneCodePattern})[0-9]{4}[0-9]{4}$`);
  const phoneNumber = `${phone.prefix}${phone.part1}${phone.part2}`;
  return regex.test(phoneNumber);
};

export const splitPhoneNumber = (phone: string | null) => {
  if (phone === null) return { prefix: "", part1: "", part2: "" };
  const prefix = phone.substring(0, 3);
  const part1 = phone.substring(3, 7);
  const part2 = phone.substring(7, 11);
  return { prefix, part1, part2 };
};

export const formatPhoneNumber = (phone: PhoneNumber) => {
  return `${phone.prefix}-${phone.part1}-${phone.part2}`;
};

export const formatPhoneNumberForPurchase = (phone: PhoneNumber) => {
  return `${phone.prefix}${phone.part1}${phone.part2}`;
};

// 소수점 세 자리 절삭
export const truncateToThreeDecimalPlaces = (num: number): number => {
  return Math.floor(num * 1000) / 1000;
};

export const scrollToRoot = (option: { isSmooth: boolean }) => {
  const { isSmooth } = option;
  const rootElement = document.getElementById("root");
  if (isSmooth) {
    return rootElement?.scrollTo({ top: 0, behavior: "smooth" }); // 원하는 위치로 스크롤
  }
  return rootElement?.scrollTo(0, 0);
};
interface Form {
  id?: number;
  postCode: string;
  address: string;
  addressDetail: string;
  isDefault: boolean;
  addressName: string;
  receiver: string;
  phone: PhoneNumber;
  mobile: PhoneNumber;
  memo: string;
  passwordDoor?: string | null;
  customMemo: string;
}

export const formatDeliveryForm = (form: Form) => {
  // 직접 입력한 경우 customMemo -> memo / 아닐 경우 memo
  const formatMemo =
    form.memo === "" ? "" : form.customMemo ? form.customMemo : form.memo;

  // 전화번호 포맷팅
  const formatPhone = formatPhoneNumberForPurchase(form.phone);
  const formatMobile = validatePhone(form.mobile)
    ? formatPhoneNumberForPurchase(form.mobile)
    : ""; // 유효하지 않은 모바일 번호는 빈 문자열로 처리

  const processedForm = {
    id: form.id,
    postCode: form.postCode,
    address: form.address,
    addressDetail: form.addressDetail,
    isDefault: form.isDefault,
    addressName: form.addressName,
    receiver: form.receiver,
    phone: formatPhone,
    mobile: formatMobile,
    memo: formatMemo,
    passwordDoor: form.passwordDoor,
  };

  return processedForm;
};

export const formatCurrency = (value: number): string => {
  return `${(value / 10000).toLocaleString()}만원`;
};

export const checkIsOdd = (value: number) => {
  return value % 2 !== 0;
};
