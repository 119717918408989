import { routes } from "@/routes";
import { ButtonBase } from "@mui/material";
import { formatTime } from "@utils/utils";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import arrowRight from "@assets/icons/ico_arrow_right.png";
import activeIcon from "@assets/icons/ico_notice_active.png";
import notiIcon from "@assets/icons/ico_notice_basic.png";
import ico_search from "@assets/icons/ico_search.png";
import DetailHeader from "@components/layouts/DetailHeader";

import useNoticeList, { NoticeItem } from "./hooks/useNoticeList";
import { divider, noticeListWrap } from "./style.css";

type Props = {};

function Notice({}: Props) {
  const { data, isLoading } = useNoticeList();
  const navigate = useNavigate();
  const [fixedList, setFixedList] = useState<NoticeItem[]>([]);
  const [openList, setOpenList] = useState<NoticeItem[]>([]);
  const [filterOpenList, setFilterOpenList] = useState<NoticeItem[]>([]);
  const [keyword, setKeyword] = useState("");

  const onChangKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const filterList = openList?.filter?.(
      (item: NoticeItem) =>
        item.subject.includes(value) || item.content.includes(value),
    );
    setFilterOpenList(filterList);
    setKeyword(value);
  };

  const renderList = useCallback((item: NoticeItem) => {
    let source = item.status === "FIXED" ? activeIcon : notiIcon;
    return (
      <div
        style={{
          display: "flex",
          padding: "17px 0",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #EAEAEA",
        }}
        onClick={() => {
          navigate(`${routes.notice}/${item.id}`);
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "48px",
              height: "48px",
              marginRight: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.08)",
                borderRadius: "16px",
                backgroundColor: "#F7F7F7",
              }}
            >
              <img
                style={{}}
                src={source}
                alt={`notice_icon_${
                  item.status === "FIXED" ? "active" : "normal"
                }`}
              />
              {item.status === "FIXED" && (
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: "#FF0000",
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                  }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              wordBreak: "keep-all",
            }}
          >
            <span
              style={{ marginBottom: "16px", color: "#444", fontWeight: "700" }}
            >
              {item.subject}
            </span>
            <span style={{ color: "#777", fontSize: "12px" }}>
              {formatTime(new Date(item.startDate))}
            </span>
          </div>
        </div>
        <img
          style={{
            width: "48px",
            height: "48px",
            objectFit: "contain",
          }}
          src={arrowRight}
          alt=""
        />
      </div>
    );
  }, []);

  useEffect(() => {
    if (data?.fixed) setFixedList(data.fixed);
    if (data?.open) setOpenList(data.open);
  }, [data?.fixed, data?.open]);

  return (
    <>
      <div className="detail">
        <DetailHeader title="공지사항" backCallback={() => navigate(-1)} />
        <div className="content">
          <div
            style={{
              padding: "24px 24px 16px",
            }}
          >
            <div
              style={{
                display: "flex",
                border: "1px solid #DBDBDB",
                borderRadius: 16,
                padding: "4px 0px 4px 16px",
              }}
            >
              <input
                type="text"
                name="keyword"
                id="keyword"
                title="키워드입력"
                placeholder="검색어를 입력해주세요"
                value={keyword}
                onChange={(e) => {
                  onChangKeyword(e);
                }}
                style={{
                  border: 0,
                  flex: 1,
                  fontSize: 18,
                  padding: 0,
                  outline: "none",
                }}
              />
              <ButtonBase
                style={{
                  width: "48px",
                  height: "48px",
                }}
              >
                <img src={ico_search} alt="" />
              </ButtonBase>
            </div>
          </div>
          <div className={divider} />
          <div className={noticeListWrap}>
            {fixedList.map((item) => {
              return renderList(item);
            })}
            {(keyword.length > 0 ? filterOpenList : openList).map((item) => {
              return renderList(item);
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Notice;
