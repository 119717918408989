import { showToast } from "@features/toast/toastSlice";
import { ch2pattern } from "@utils/utils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import btnX16 from "@assets/icons/btn_x_16.png";

import iconSearch from "../../../assets/icons/ico_search.png";
import iconLeft from "../../../assets/icons/icon_left.png";
import useGoodsList from "../hooks/useGoodsList";
import { getMatchedItem, toUniqueArrayWithLength } from "./Search.const";
import SearchFailed from "./components/SearchFailed";
import SearchReady from "./components/SearchReady";
import SearchSuccess from "./components/SearchSuccess";
import { useSearch } from "./hooks/useSearch";

const createFuzzyMatcher = (input: any) => {
  const pattern = input.split("").map(ch2pattern).join(".*?");
  return new RegExp(pattern);
};

const Search = () => {
  const navigate = useNavigate();
  const { data: goodsListData } = useGoodsList();
  const dispatch = useDispatch();
  const { searchObject, setSearchObject, hanldeSearchInput, deleteHistory } =
    useSearch();

  const handleSearch = (eventInput: string) => () => {
    if (eventInput === "") {
      return dispatch(
        showToast({
          message: "상품명 및 중량을 입력해주세요",
          icon: "caution",
        }),
      );
    }

    const isResult = goodsListData?.filter(({ goodsName }) => {
      return createFuzzyMatcher(eventInput).test(goodsName);
    });
    const isSearchInputBlank = eventInput === "";

    if (
      (isResult && isResult.length > 0) ||
      (isResult && isResult?.length > 0 && searchObject.step === "SUCCESS")
    ) {
      if (isSearchInputBlank) {
        return setSearchObject((prev) => ({
          step: "SUCCESS",
          searchInput: eventInput,
          lastSearched: eventInput,
          searchHistory: prev.searchHistory,
        }));
      }
      return setSearchObject((prev) => ({
        step: "SUCCESS",
        searchInput: eventInput,
        lastSearched: eventInput,
        searchHistory: toUniqueArrayWithLength(
          [eventInput].concat(prev.searchHistory),
          20,
        ),
      }));
    }

    // 검색 실패 시
    return setSearchObject((prev) => ({
      step: "FAILED",
      searchInput: eventInput,
      lastSearched: eventInput,
      searchHistory: toUniqueArrayWithLength(
        [eventInput].concat(prev.searchHistory),
        20,
      ),
    }));
  };

  const matchedItems = getMatchedItem(searchObject, goodsListData);

  return (
    <div className="search_wrap">
      <div className="search_top">
        <input
          type="text"
          id="search-1"
          placeholder="상품명 및 중량을 검색하세요."
          value={searchObject.searchInput}
          onChange={(e) => hanldeSearchInput(e.target.value, "WORD")}
        />
        <label htmlFor="search-1">
          <img
            src={iconLeft}
            alt=""
            className="icon_back"
            onClick={() => navigate(-1)}
          />
          {searchObject.searchInput.length > 0 && (
            <>
              <img
                style={{ height: 17, position: "absolute", right: 60, top: 20 }}
                src={btnX16}
                onClick={() => hanldeSearchInput("", "ALL")}
              />
              <img
                src={iconSearch}
                alt=""
                className="search_icon"
                onClick={handleSearch(searchObject.searchInput)}
              />
            </>
          )}
        </label>
      </div>
      {searchObject.step === "READY" && (
        <SearchReady
          recentKeywords={searchObject.searchHistory}
          hanldeSearchInput={hanldeSearchInput}
          handleSearch={handleSearch}
          deleteHistory={deleteHistory}
        />
      )}
      {searchObject.step === "SUCCESS" && (
        <SearchSuccess matchedItems={matchedItems} />
      )}
      {searchObject.step === "FAILED" && (
        <SearchFailed
          hanldeSearchInput={hanldeSearchInput}
          handleSearch={handleSearch}
        />
      )}
    </div>
  );
};

export default Search;
