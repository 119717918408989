import { ROLE, routes } from "@/routes";
import { store } from "@app/store";
import { updateAuth } from "@features/auth/authSlice";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { showToast } from "@features/toast/toastSlice";
import { userRefresh } from "@features/user/userSlice";
import useCustomMutation from "@hooks/queries/useCustomMutation";
import usePendingCheck from "@hooks/usePendingCheck";
import * as APIS from "@services/apis";
import { STORAGE_KEY } from "@utils/constants";
import { decodeJwt, postMessage } from "@utils/utils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IAccountInfo } from "@pages/myPage/hooks/useAccountInfo";

interface AccessToken {
  name: string;
  roles: string;
  sns: string;
  sub: string;
  version: string;
}

export interface IBankProps {
  bankCode: string;
  number: string;
  bankType: string;
}

interface IError {
  response?: {
    data?: {
      message: string;
    };
  };
}

const useBankAccountAssign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pendingCheck = usePendingCheck();

  const pendingCompleteAccountCheck = (bankType: string) => {
    pendingCheck<IAccountInfo>({
      checkFn: () => APIS.getAccountInfo(bankType),
      successCondition: (data) => data.bankAccountStatus === "COMPLETED",
      processingCondition: (data) => data.bankAccountStatus === "REQUEST",
      processingMessage: "계좌 등록 중 입니다.",
      failureMessage: "계좌 등록에 실패하였습니다.",
      navigateOnSuccess: () => {
        dispatch(
          showToast({ message: "계좌가 등록되었습니다.", icon: "success" }),
        );
        navigate(-1);
      },
      navigateOnProcessing: () => navigate(routes.menu),
    });
  };

  const updateToken = (code: any) => {
    APIS.getAuthToken(code).then(
      ({ data: { success, data, message: errorMessage } }) => {
        if (success) {
          let accessToken = data.authToken;
          if (accessToken) {
            sessionStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
            APIS.setAuthorization(accessToken);
            const {
              name,
              roles = "NONE",
              sns,
              sub = "",
              version = "",
            } = decodeJwt(accessToken) as AccessToken;
            const ids = sub.split(":");
            let role = ROLE["NONE"];
            const splitRoles = roles.split(",");

            for (let name of splitRoles) {
              if (role?.LEVEL < ROLE[name]?.LEVEL) {
                role = ROLE[name];
              }
            }
            dispatch(
              updateAuth({
                isSigned: true,
                name,
                role,
                sns,
                socialId: +ids[0] || 0,
                userId: +ids[1] || 0,
                version,
                roles: splitRoles,
              }),
            );
            store.dispatch(userRefresh);
            if (accessToken) {
              const param = {
                command: "SEND_TOKEN",
                data: {
                  authToken: accessToken,
                  apiBaseUrl: import.meta.env.VITE_API_URL,
                },
              };
              postMessage(param);
            }
            if (accessToken) {
              APIS.putNotificationClear();
            }
          }
        } else {
          dispatch(showToast({ message: errorMessage, icon: "error" }));
        }
      },
    );
  };

  const mutationFn = async (params: IBankProps): Promise<any> => {
    dispatch(showLoading());
    try {
      const response = await APIS.postBankAccount(params);
      dispatch(hideLoading());
      if (response.data.success) {
        pendingCompleteAccountCheck(params.bankType);
        updateToken(response.data.data.code);
      } else {
        dispatch(showToast({ message: response.data.message, icon: "error" }));
      }
    } catch (error) {
      dispatch(hideLoading());
      const err = error as IError;
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          showToast({ message: err.response.data.message, icon: "error" }),
        );
      }
      throw error;
    }
  };

  const mutation = useCustomMutation<IBankProps>(
    [routes.myAccountVerification],
    mutationFn,
  );

  return mutation;
};

export default useBankAccountAssign;
