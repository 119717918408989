import axios from "axios";
import queryString from "query-string";

import { IPurchaseInfo } from "@features/purchase/purchaseSlice";
import { formatTime, objToQueryString } from "@utils/utils";

import type { APIResponse } from "./../utils/types";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.baseURL = import.meta.env.VITE_API_URL;

const commonInstance = axios.create({ headers: { Authorization: "" } });

export const setAuthorization = (authToken: any) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
};

export const initAuthorization = () => {
  axios.defaults.headers.common["Authorization"] = "";
};

export const getOauthUrls = () => {
  return axios.get("/api/v2/user/auth/oauth/urls");
};

// 이메일 저장 또는 수정
export const postUserEmailModify = (param: any) => {
  return axios.post(`/api/v2/user/auth/user/email/modify`, param);
};

export const getServiceBreak = async () => {
  const from = await getEnvConfig("FROM_STOP_TIME").then(
    ({
      data: {
        success,
        data: { value },
      },
    }) => {
      if (success) {
        return `${value.substring(0, 2)}:${value.substring(
          3,
          5,
        )}:${value.substring(6, 8)}`;
      } else {
        return "00:00:00";
      }
    },
  );
  const to = await getEnvConfig("TO_STOP_TIME").then(
    ({
      data: {
        success,
        data: { value },
      },
    }) => {
      if (success) {
        return `${value.substring(0, 2)}:${value.substring(
          3,
          5,
        )}:${value.substring(6, 8)}`;
      } else {
        return "00:00:00";
      }
    },
  );
  const now = formatTime(new Date(), "HH:mm:SS");

  // 브레이크 중 날이 바뀔 경우 (자정을 사이에 둘 경우)
  if (from > to) {
    if (from <= now || now < to) {
      return `지금은 서비스 점검 시간 입니다.\n(서비스 점검 : ${from} ~ ${to})`;
    }
  }

  // 동일한 날 브레이크가 끝날 경우
  if (from < to) {
    if (from <= now && now < to) {
      return `지금은 서비스 점검 시간 입니다.\n(서비스 점검 : ${from} ~ ${to})`;
    }
  }

  return "";
};

export const getAuthToken = (code: any) => {
  return axios.post(
    `/api/v2/user/auth/oauth/token`,
    { code },
    { headers: { Authorization: "" } },
  );
};

export const getEnvConfig = (config: string, asset?: any) => {
  return commonInstance.get(
    `/api/v2/user/shared/code/envconfig?${queryString.stringify({
      config,
      asset,
    })}`,
  );
};

export const getSelfVerificationRequestId = (purpose: string) => {
  return axios.post(`/api/v2/user/verification/self/prepare`, { purpose });
};

// 아이디로 로그인
export const postSignInId = (param: any) => {
  return axios.post(`/api/v2/user/auth/id/signin`, param);
};

// 회원가입 단계 저장
export const postSignUpStep = (param: string) => {
  return axios.post(`/api/v2/user/verification/self/signup-step`, {
    step: param,
  });
};

export const getUserInfo = () => {
  return axios.get("/api/v2/user/auth/user/me");
};

export const getUserHoldingsInfo = () => {
  return axios.get(`/api/v2/user/asset`);
};

// 평가 손익
export const getUserAssetInfo = () => {
  return axios.get(`/api/v2/user/asset/info`);
};

export const postSignOut = () => {
  return axios.post(`/api/v2/user/auth/oauth/logout`);
};

/**
 * 홈
 */
//시세값 조회
export const getCurrentClosingMarketPrice = (type: string) => {
  return commonInstance.get(
    `/api/v2/user/market/buysell/currentclosingmarketprice?type=${type}`,
  );
};

//수수료율 조회
export const getBuySellPolicy = (tradeType: string, assetType: string) => {
  return commonInstance.get(
    `/api/v2/user/market/buysell/policy?${queryString.stringify({
      tradeType,
      assetType,
    })}`,
  );
};

// 공지사항 목록 조회
export const getNotice = () => {
  return commonInstance.get(`/api/v2/user/notice`);
};

// 공지사항 상세 조회
export const getNoticeDetail = (id: string) => {
  return commonInstance.get(`/api/v2/user/notice/${id}`);
};

// 이벤트 목록 조회
export const getEvent = () => {
  return commonInstance.get(`/api/v2/user/event`);
};

// 푸쉬 알림 조회
export const getNotifications = () => {
  return axios.get(`/api/v2/user/notification/push`);
};

// 푸쉬 알림 클리어
export const putNotificationClear = () => {
  return axios.put(`/api/v2/user/notification/push`);
};
// 하단 팝업 조회
export const getPopups = () => {
  return commonInstance.get(`/api/v2/user/popups`);
};

/**
 * 팔래요
 */
// 감정평가 간편감정 제품 조회
export const getAppraisalProducts = (assetType: string) => {
  return axios.get(
    `/api/v2/user/market/appraisal/appraisalProducts?${queryString.stringify({
      assetType,
    })}`,
  );
};

// 감정 평가 대리점 조회 (팔래요)
export const getAppraisalShop = (shopSearchIf?: any) => {
  const query = shopSearchIf || "";
  return axios.get(`/api/v2/user/shared/shop/appraisal${query}`);
};

// 홈에서 대리점 조회시 사용(미사용)
export const getNearbyShop = (shopSearchIf?: any) => {
  const query = shopSearchIf || "";
  return axios.get(`/api/v2/user/shared/shop${query}`);
};

// 대리점 변경
export const updateUsingShop = (param: any) => {
  return axios.post("/api/v2/user/auth/user/using_shop/modify", param);
};

//  대리점 감정 예약 API
// 현장 감정 접수
export const postAppraiseRequest = (data: object) => {
  return axios.post(`/api/v2/user/market/appraisal/Requests`, data);
};

interface BusinessHoursList {
  id: number;
  shop: any;
  dayWeek: string;
  isFullDayOff: boolean;
  openHr: string;
  closeHr: string;
  managerMemo: any;
}
interface HolidaysList {
  id: number;
  isFullDayOff: boolean;
  manageDate: string;
  openHr?: string;
  closeHr?: string;
  managerMemo: any;
  searchYear: any;
}

export interface ShopHoursResponse {
  businessHoursList: BusinessHoursList[];
  holidaysList: HolidaysList[];
}

// 매장 영업일 API : /api/v2/user/shared/shop/hours/{id} -- MALL 참고 할 것.
// 매장 휴무일 조회
export const getShopHours = (shopId: number, date: string) => {
  return axios.get<APIResponse<ShopHoursResponse>>(
    `/api/v2/user/shared/shop/hours/${shopId}?workYm=${date}`,
  );
};

// 매장 상세 조회
export const getShopDetail = (shopId: string) => {
  return axios.get(`/api/v2/user/shared/shop/detail/${shopId}`);
};

/**
 * 주문 공통
 */

// 매수/매도 요청
export const postRequestTrade = (param: any) => {
  return axios.post(`/api/v2/user/market/buysell/requesttrade`, param);
};

interface ExpectTradeItem {
  assetType: string;
  tradeType: string;
  orderPrice: number;
  tradeGram: number;
  tradeKrw: number;
  tradeFee: number;
}

// 매수/매도 예상 결제 금액 조회 (시장가)
export const postExpectTrade = (param: any) => {
  return axios.post<APIResponse<ExpectTradeItem>>(
    `/api/v2/user/market/buysell/requesttrade/expect`,
    param,
  );
};

/**
 * 호가창
 */

// 미체결 목록
export const getPendingSummary = (assetType: string) => {
  return axios.get(
    `/api/v2/user/market/buysell/pendingsummary?assetType=${assetType}`,
  );
};

// 나의 미체결 내역
export const getProcessingList = (assetType: string) => {
  return axios.get(`/api/v2/user/market/buysell/processing/${assetType}/list`);
};

// 주문 취소
export const deleteBuySell = (id: any) => {
  return axios.delete(`/api/v2/user/market/buysell/${id}`);
};

// 주문 취소 확인
export const getDeleteBuySell = (id: any) => {
  return axios.get(`/api/v2/user/market/buysell/${id}`);
};

/**
 * 자산 팔래요
 */

// 주문 가능 금액
export const getAvailableAmount = () => {
  return axios.get(`/api/v2/user/market/buysell/availableamount`);
};

// 추천가 금액 조회
export const getRecommendPrice = (assetType: string, tradeType: string) => {
  return axios.get(
    `/api/v2/user/market/buysell/recommend-price?${queryString.stringify({
      assetType,
      tradeType,
    })}`,
  );
};

/**
 * 살래요 (인출)
 */

//배너 조회
export const getBanner = (querys: string) => {
  return commonInstance.get(`/api/v2/user/banner${querys}`);
};
// 상품 상세
export const getGoodsDetail = (goodsId: string) => {
  return axios.get(`/api/v2/user/market/goods/${goodsId}`);
};
// 상품 카테고리
export const getGoodsCategory = () => {
  return axios.get(`/api/v2/user/market/goods/category`);
};
// 상품 리스트
export const getGoodsList = () => {
  return axios.get(`/api/v2/user/market/goods`);
};
// 상품 그룹 - 인기상품 호출용
export const getGoodsGroup = () => {
  return axios.get("/api/v2/user/market/goods-group");
};

// 대리점 찾기
export const getShop = (goodsId: string) => {
  return axios.get(`/api/v2/user/market/goods/buy/${goodsId}/shop`);
};

export interface IPrepriceProps {
  goodsId: string;
  useGram: number;
  qty: number;
}
export interface IPreprice {
  message: string;
  isAvailable: boolean;
  price: number;
  buyPrice: number;
  balanceGrams: number;
}

// 결제 예상금액 조회
export const getPrePrice = (params: IPrepriceProps) => {
  return axios.get<APIResponse<IPreprice>>(
    `api/v2/user/market/goods/buy/pre-price${objToQueryString(params)}`,
  );
};

// 살래요 결제
export const putPurchase = (goodsId: string, data: IPurchaseInfo) => {
  return axios.put(`/api/v2/user/market/goods/buy/approval/${goodsId}`, data);
};

// 살래요 결제 확인 요청
export const getPurchaseCheck = (goodsMallRequestId: string) => {
  return axios.get(
    `api/v2/user/market/goods/buy/approval/${goodsMallRequestId}`,
  );
};

// 살래요 결제 정보 조회
export const getPreApproval = (txId: string) => {
  return axios.get(`api/v2/user/market/goods/buy/pre-approval/${txId}`);
};

// 살래요 결제 정보 저장
export const putPreApproval = (data: any) => {
  return axios.put(`api/v2/user/market/goods/buy/pre-approval`, data);
};

/**********************************************************************
 * 계좌 등록 관리
 **********************************************************************
 */

// 계좌 정보 조회
export const getAccountInfo = (bankType: string) => {
  return axios.get(
    `/api/v2/user/asset/bankaccount/assign/account_info/${bankType}`,
  );
};

// 입금 계좌를 출금 계좌로 사용
export const postWithdrawFromDeposit = () => {
  return axios.post(`/api/v2/user/asset/bankaccount/withdraw_from_deposit`);
};

// 출금 계좌를 입금 계좌로 사용
export const postDepositFromWithdraw = () => {
  return axios.post(`/api/v2/user/asset/bankaccount/deposit_from_withdraw`);
};

// 은행 코드 조회
export const getBankList = () => {
  return axios.get(`/api/v2/user/shared/code/bank`);
};

// 신규 계좌 등록
export const postBankAccount = (param: any) => {
  return axios.post(`/api/v2/user/asset/bankaccount/assign`, param);
};

// 출금 요청
export const postWithdrawal = (param: any) => {
  return axios.post(`/api/v2/user/market/withdrawal/withdrawal`, param);
};

// 출금 요청 결과 확인 API
export const getWithdrawalCheck = (withdraw_id: string) => {
  return axios.get(`/api/v2/user/market/withdrawal/check/${withdraw_id}`);
};

export const postSelfVerificationComplete = (param: any) => {
  return axios.post(`/api/v2/user/verification/self/complete`, param);
};

/**********************************************************************
 * 거래내역
 **********************************************************************
 */
// 진행 중 내역 조회
export const getTradeHistoryIngList = () => {
  return axios.get(`/api/v2/user/trade/ing/list`);
};

// 거래완료 조회
export const getTradeHistoryCompleteList = (param: any) => {
  return axios.get(`/api/v2/user/trade/complete${objToQueryString(param)}`);
};

// 감정평가 > 상세 조회
export const getAppraisalRequestDetail = (id: string) => {
  return axios.get(`/api/v2/user/market/appraisal/Requests/${id}`);
};

// 감정평가 > 상세 > 접수 취소
export const deleteAppraisalRequest = (id: string) => {
  return axios.put(`/api/v2/user/market/appraisal/Requests/${id}`);
};

// 감정평가 방문정보 변경
export const putAppraisalRequestsInfo = (id: string, param: any) => {
  return axios.put(
    `/api/v2/user/market/appraisal/Requests/${id}/infoChange`,
    param,
  );
};

// 감정평가 > 상세 > 상태 변경
export const putAppraisalRequestsStatus = (id: string, param: any) => {
  return axios.put(
    `/api/v2/user/market/appraisal/Requests/${id}/status`,
    param,
  );
};

// 감정평가 > 상세 > 상태 변경 대기
export const getAppraisalRequestPending = (id: string) => {
  return axios.get(
    `/api/v2/user/market/appraisal/Requests/${id}/exchange-complete`,
  );
};

// 실물인출 > 상세 조회
export const getShoppingDetail = (transId: string) => {
  return axios.get(`/api/v2/user/market/goods/buy/${transId}/detail`);
};

// 실물인출 > 상세 > 구매 확정
export const putBuyConfirm = (transId: string, data: any) => {
  return axios.put(`/api/v2/user/market/goods/buy/${transId}/confirm`, data);
};

// 실물인출 > 상세 > 구매확정 조회
export const getBuyConfirm = (transId: string) => {
  return axios.get(`/api/v2/user/market/goods/buy/${transId}/confirm`);
};

// 이벤트 포인트 지급 (구매확정)
export const postBuyEventPoint = (data: any) => {
  return axios.post(`/api/v2/user/market/goods/buy/point-reg`, data);
};

export interface GoodsBuyShopChange {
  isDelivery: false;
  visitDate: string;
  shopId: number;
}
export interface GoodsBuyAddressChange {
  isDelivery: true;
  delivery: {
    receiver: string;
    addressName: string;
    postCode: string;
    address: string;
    addressDetail: string;
    phone: string;
    mobile: string;
    memo: string;
  };
}

export type GoodsBuyDeliveryPayload =
  | GoodsBuyShopChange
  | GoodsBuyAddressChange;

// 실물인출 > 상세 > 배송지 및 대리점 변경
export const putGoodsBuyDelivery = (
  transId: string,
  data: GoodsBuyDeliveryPayload,
) => {
  return axios.put(
    `/api/v2/user/market/goods/buy/${transId}/delivery/change`,
    data,
  );
};

// 실물인출 > 상세 > 주문 취소
export const deleteGoodsBuy = (transId: string) => {
  return axios.delete(`/api/v2/user/market/goods/buy/${transId}/cancel`);
};

/**********************************************************************
 * 탈퇴
 **********************************************************************
 */

// 탈퇴 가능 자산 여부 조회
export const getLeaveAsset = () => {
  return axios.get(`/api/v2/user/auth/leave/asset`);
};

// 탈퇴 사유 조회
export const getLeaveReason = () => {
  return axios.get(`/api/v2/user/auth/leave/reason`);
};

// 탈퇴 본인 인증 sms 발송
export const postLeaveVerifyCode = () => {
  return axios.post(`/api/v2/user/auth/leave/verifyCode`);
};

// 탈퇴 본인 인증 sms 검증
export const patchLeavePreviousVerify = (smsVerifyCode: any) => {
  return axios.patch(`/api/v2/user/auth/leave/previousVerify`, {
    smsVerifyCode,
  });
};

// 임시회원 회원 탈퇴 요청
export const patchLeaveSocial = () => {
  return axios.patch(`/api/v2/user/auth/leave/social`);
};

// 인증 회원 탈퇴 요청
export const patchLeaveUser = (leaveReason: any) => {
  return axios.patch(`/api/v2/user/auth/leave`, { leaveReason });
};

/**********************************************************************
 * 1:1 문의하기
 **********************************************************************
 */

export const getQnaList = () => {
  return axios.get(`/api/v2/user/qna`);
};

export const postQnaRegister = (data: any) => {
  return axios.post(`/api/v2/user/qna`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**********************************************************************
 * 보관료
 **********************************************************************
 */

// 보관료 조회
export const getStorageFee = () => {
  return axios.get(`/api/v2/user/market/storagefee/feelist`);
};

// 보관료 납부
export const payStorageFee = (body: any) => {
  return axios.post(`/api/v2/user/market/storagefee/pay`, body);
};

/**********************************************************************
 * 본인 인증
 **********************************************************************
 */
//본인인증 시 이용약관 조회
export const getTerms = () => {
  return axios.get(`/api/v2/user/shared/terms`);
};

//본인인증 시 이용약관 디테일 조회
export const getTermsDetail = (type: string) => {
  return axios.get(`/api/v2/user/shared/terms/${type}`);
};

export const postMallToApp = (param: any, token: any) => {
  return axios.post(`/api/v2/user/verification/self/mall-to-app`, param, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

// SNS 유저 이메일 조회
export const getSnsEmail = () => {
  return axios.get(`/api/v2/user/auth/user/email`);
};

// 추천 대리점 조회
export const getShopCity = (id: number) => {
  return axios.get(`/api/v2/user/shared/shop/city/${id}`);
};

/**********************************************************************
 * 배송지 관리
 **********************************************************************
 */

// 배송지 목록 조회
export const getAddressList = () => {
  return axios.get(`/api/v2/user/auth/user/address`);
};

// 배송지 등록
export const postRegisterAddress = (data: any) => {
  return axios.post(`/api/v2/user/auth/user/address`, data);
};

// 배송지 수정
export const putModifyAddress = (id: number, data: any) => {
  return axios.put(`/api/v2/user/auth/user/address/${id}`, data);
};

// 배송지 삭제
export const deleteAddress = (id: number) => {
  return axios.delete(`/api/v2/user/auth/user/address/${id}`);
};
