import { RootState, store } from "@app/store";
import { hideLoading, showLoading } from "@features/loading/loadingSlice";
import { showToast } from "@features/toast/toastSlice";
import { userRefresh } from "@features/user/userSlice";
import { ButtonBase } from "@mui/material";
import { postUserEmailModify } from "@services/apis";
import { validateEmail } from "@utils/utils";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import iconLeft from "@assets/icons/icon_left.png";
import colors from "@assets/styles/colors.css";
import { checkedAfter } from "@components/common/account-verification/styles.css";

import {
  backBtn,
  emailContentTitle,
  emailContentWrap,
  headerImg,
  headerTitle,
  headerWrap,
} from "./style.css";

type Props = {};

function EditEmail({}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [email, setEmail] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [targetHeight, setTargetHeight] = useState(
    textareaRef.current?.scrollHeight || 32,
  );

  const originEmail = userInfo.email;

  const handleClickChange = () => {
    if (email === "" || !validateEmail(email)) {
      dispatch(
        showToast({ message: "이메일을 확인해주세요", icon: "caution" }),
      );
      return;
    }
    dispatch(showLoading());
    postUserEmailModify({ email })
      .then(({ data: { success, message } }) => {
        if (success) {
          store.dispatch(userRefresh);
          dispatch(
            showToast({
              message: "이메일 변경이 완료되었습니다.",
              icon: "success",
            }),
          );
          navigate(-1);
        } else {
          dispatch(showToast({ message: message, icon: "error" }));
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
    }
    setEmail(e.target.value);
  };

  return (
    <div>
      <div className={headerWrap}>
        <div
          className={backBtn}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src={iconLeft}
            alt=""
            className={headerImg}
            // onClick={handlePrevStep}
          />
          <p className={headerTitle}>이메일 변경</p>
        </div>
      </div>
      <div className={emailContentWrap}>
        <h3 className={emailContentTitle}>변경할 이메일을 입력하세요.</h3>
        <div className="sell_sub_wrap01 change">
          <ul>
            <li
              className={`disabled`}
              style={{
                borderTopLeftRadius: 18,
                borderTopRightRadius: 18,
              }}
              // onClick={handleOpenSelectBank}
            >
              <p>나의 이메일</p>
              <h3>{originEmail}</h3>
            </li>
            {/* <li className={`price02 ${isInputFocused ? "checked" : ""}`}>
              <input
                placeholder="이메일 주소를 입력해주세요."
                id="num"
                style={{}}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
              />
              <label htmlFor="num" style={{ width: "100%" }}>
                <p>변경할 이메일 입력</p>
              </label>
            </li> */}
            <li className={`price02 ${isInputFocused ? "checked" : ""}`}>
              <textarea
                ref={textareaRef}
                placeholder="이메일 주소를 입력해주세요."
                id="num"
                rows={1}
                style={{
                  resize: "none",
                  height: "auto",
                  padding: 0,
                }}
                value={email}
                onChange={(e) => {
                  onChangeTextArea(e);
                }}
                onKeyDown={() => {}}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => {
                  if (textareaRef.current)
                    textareaRef.current.style.height = "auto";
                  setIsInputFocused(false);
                }}
              />
              <label htmlFor="num" style={{ width: "100%" }}>
                <p>변경할 이메일 입력</p>
              </label>
            </li>
          </ul>
        </div>
        <div className="sub_btn_wrap">
          <button
            className={`sub_wrap02_btn ${email !== "" ? "on" : ""}`}
            onClick={() => {
              handleClickChange();
            }}
            style={{
              backgroundColor: email !== "" ? colors.buttonColor : "",
            }}
          >
            변경하기
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditEmail;
